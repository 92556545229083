body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.skiptranslate.goog-te-gadget span,.goog-te-banner-frame{
  display: none !important;
}
.goog-te-gadget{
  color:white !important;
}
.d_flex_center{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.d_flex_start{
  display: flex;
  align-items: center;
  justify-content: start;
}
.d_grid_column{
  display: grid;
  grid-template-columns: 50% 50%;
}
.text_align_start{
  text-align: start;
}
.text_align_end{
text-align: end;
}